const state = {
  Period: [],
  Period_Detail: [],
};

const getters = {
  Period: (state) => state.Period,
};

const mutations = {
  set_period: (state, Period) => {
    state.Period = JSON.parse(JSON.stringify(Period));
  },
  set_period_detail: (state, Period_Detail) => {
    state.Period_Detail = JSON.parse(JSON.stringify(Period_Detail));
  },
};

const actions = {
  get_data(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("data/period/get_data.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
            console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_period_detail", data.response.PERIOD_DETAIL);
              resolve(data.response);

              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  new_period(context, credentials){
    return new Promise((resolve, reject) => {
        this._vm.$http
          .post("data/period/new_period.php", credentials, this._vm.$headers)
          .then(({ data, status }) => {
            console.log(data);
            if (status === 200) {
              if (data.message == "Success") {
                //console.log(data);
                // context.commit("set_genaral_list", data.response.genaral_list);
                resolve(data.response);
                //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
              } else {
                //console.log(data);
  
                reject(data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
  },
  get_period_detail(context, credentials){
    return new Promise((resolve, reject) => {
        this._vm.$http
          .post("data/period/get_detail.php", credentials, this._vm.$headers)
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.message == "Success") {
                //console.log(data);
                //context.commit("set_genaral_list", data.response.genaral_list);
                resolve(data.response);
                //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
              } else {
                //console.log(data);
  
                reject(data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });    
  },
};

export const period = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
