const state = {
    Pages: [],
    PagesDetail: [{"PriceSum":0,"PriceSumLoy": 0,"WinSum": 0,"WinSumLoy": 0,}],//PriceSum,PriceSumLoy,WinSum,WinSumLoy
};

const getters = {
    Pages:state=>state.Pages,
    PagesDetail:state=>state.PagesDetail,
};

const mutations = {
    set_list: (state,Pages) => {
        state.Pages = JSON.parse(JSON.stringify(Pages));
        state.PagesDetail["PriceSum"] =0;
        state.PagesDetail["PriceSumLoy"] =0;
        state.PagesDetail["WinSum"] =0;
        state.PagesDetail["WinSumLoy"] =0;
        state.PagesDetail["WaitingSend"] =0;
        for (let Key in Pages) {
            if(Pages[Key].Status == 1 || Pages[Key].Status == 3 || Pages[Key].Status == 4){
                state.PagesDetail["PriceSum"] += parseInt(Pages[Key].PriceSum);
                state.PagesDetail["PriceSumLoy"] += parseInt(Pages[Key].PriceSumLoy);
                state.PagesDetail["WinSum"] += parseInt(Pages[Key].WinSum);
                state.PagesDetail["WinSumLoy"] += parseInt(Pages[Key].WinSumLoy);
            }

            if(parseInt(Pages[Key].PriceSum)+parseInt(Pages[Key].PriceSumLoy) > 0 &&
            Pages[Key].Status==0 )
                state.PagesDetail["WaitingSend"] += 1;
        }

    }
};

const actions = {
    get_list(context, credentials){
        console.log(credentials);
        return new Promise((resolve, reject) => {
            this._vm.$http
            .post("pages/get_list.php",credentials,this._vm.$headers)
            .then(({data,status}) =>{
                // console.log(data);
                if(status===200){
                    if(data.message=="Success"){
                        //console.log(data);
                        context.commit('set_list',data.response.pagelist);
                        resolve(data.response);
       //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
                    }else{
                        //console.log(data);
                        reject(data.message);
                    }
                }
            })
            .catch(error =>{
               console.log(error);
               reject(error);
            });
       });
    },
    new_page(context, credentials){
        console.log(credentials);
        return new Promise((resolve, reject) => {
            this._vm.$http
            .post("pages/add.php",credentials,this._vm.$headers)
            .then(({data,status}) =>{
                // console.log(data);
                if(status===200){
                    if(data.message=="Success"){
                        //console.log(data);
                        //context.commit('set_list',data.response.pagelist);
                        resolve(data.response);
       //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
                    }else{
                        //console.log(data);
                        reject(data.message);
                    }
                }
            })
            .catch(error =>{
               console.log(error);
               reject(error);
            });
       });
    },
};

export const pages = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters  
};