<template>
  <v-app>
    <!-- <SideBar /> -->
    <v-main>
      <!-- <ToolBar/> -->
      <router-view></router-view>
    </v-main>
    <!-- <BottomNavigation/> -->
  </v-app>
</template>

<script>
// import BottomNavigation from "./components/BottomNavigation";
// import ToolBar from "./components/ToolBar";
// import SideBar from "./components/SideBar";

export default {
  name: "App",

  components: {
    // BottomNavigation,
    // ToolBar,
    // SideBar,
  },
  mounted() {
    if (
      (this.$store.getters["auth/Token"] == null &&
        this.$router.currentRoute.path != "/") ||
      (this.$store.getters["auth/ID"] == null &&
        this.$router.currentRoute.path != "/")
    ) {
      this.$router.push("/");
    }
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
.v-application {
  font-family: "Noto Serif Thai";
}
</style>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px !important;
}

.v-icon {
  font-size: 1.5em !important;
}

/* @media only screen and (min-width: 800) {
  * {
    font-size: 16px !important
  }
} */
@media only screen and (min-width: 850px) {
  * {
    font-size: 16px !important;
  }
  .v-icon {
    font-size: 1.3em !important;
  }
}
</style>
