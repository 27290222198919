const state = {
  isLoggingIn: false,
  errors: {},
  user: [],
};

const getters = {
  ID: (state) => state.user.id,
  Token: (state) => state.user.token,
  // getID: state => {
  //     return state.user.id
  // }
};

const mutations = {
  loginSuccess(state, user) {
    state.user.id = user.id;
    state.user.nickname = user.nickname;
    state.user.type = user.type;
    state.user.token = user.token;
    state.user.credit = user.credit;
    console.log(state.user.id);
  },
};

const actions = {
  login(context, login_data) {
    // context.commit(AuthAction.local.IS_REGISTERING, true);
    // return new Promise((resolve, reject) => {
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", login_data, this._vm.$headers)
        .then(({ data, status }) => {
          //    console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              //   context.commit("loginSuccess", data.response.user);

              resolve(data.response.Pincode);
              localStorage.removeItem("blog_token");
              //save token
              localStorage.setItem("blog_token", data.response.token);
              //save host_id
              sessionStorage.removeItem("blog_token");
              sessionStorage.setItem("host_id", data.response.user.id);
              //save round
              sessionStorage.setItem("period", data.response.period);
              //save nickname
              sessionStorage.setItem("nickname", data.response.user.nickname);

              this._vm.$headers = {
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
                  Authorization: "Bearer " + localStorage.getItem("blog_token"),
                },
              };
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
  ck_token() {
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/ck_token.php", "", this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            console.log(data);
            if (data.message == "Success") {
              // console.log(data);
              //context.commit("Success");
              sessionStorage.setItem("nickname", data.response.SubHostName);
              resolve(data.response.SubHostName);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
  create_pincode(context, parameter) {
    if (this._vm.$headers["headers"]["Authorization"] == "Bearer null"){
        this._vm.$headers = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: "Bearer " + localStorage.getItem("blog_token"),
            },
          };
    }
    // console.log(parameter);
    // console.log(localStorage.getItem("blog_token"));
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("verify/create.php", parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            // console.log(data);
            if (data.message == "Success") {
            //   this._vm.cache.clear();

              resolve("Success");
            } else {
              console.log("error");
              console.log(data.message);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
  verify_pincode(context, parameter) {
    if (this._vm.$headers["headers"]["Authorization"] == "Bearer null"){
        this._vm.$headers = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              Authorization: "Bearer " + localStorage.getItem("blog_token"),
            },
          };
    }
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("verify/", parameter, this._vm.$headers)
        .then(({ data, status }) => {
          if (status === 200) {
            // console.log(data);
            if (data.message == "Success") {
              //save host_id
              sessionStorage.setItem("host_id", data.response.user.id);
              //save round
              sessionStorage.setItem("period", data.response.period);
              //save nickname
              sessionStorage.setItem("nickname", data.response.user.nickname);
              resolve("Success");
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
