import Vue from 'vue'
import Vuex from 'vuex'

// import * as User from './modules/currentUser'

import {auth} from './modules/auth'
import {period} from './modules/period'
import {lotto} from './modules/lotto'
import {hp} from './modules/hp'
import {line} from './modules/line'
import {agent} from './modules/agent'
import {pages} from './modules/pages'
import {page} from './modules/page'
import {bookie} from './modules/bookie'
import {export_process} from './modules/export_process'
import {export_decide} from './modules/export_decide'
import {export_final} from './modules/export_final'
import {export_page} from './modules/export_page'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    period,
    lotto,
    hp,
    line,
    agent,
    pages,
    page,
    bookie,
    export_process,
    export_decide,
    export_final,
    export_page,
  }
})
