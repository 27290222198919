import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueHtmlToPaper from "vue-html-to-paper";
import VueSimpleAlert from "vue-simple-alert";
import VueHtml2Canvas from "vue-html2canvas";

Vue.use(VueHtml2Canvas);

Vue.use(VueSimpleAlert);

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.prototype.$headers = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    Authorization: "Bearer " + localStorage.getItem("blog_token"),
  },
};

// axios.defaults.baseURL = "http://192.168.1.2/FN2021/host/service/";
axios.defaults.baseURL = '/service/';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("blog_token");
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";

// axios.interceptors.response.use(
//     (resp) => {
//       let fe_version = resp.headers['fe-version'] || 'default'
//       if(fe_version !== localStorage.getItem('fe-version') && resp.config.method == 'get'){
//         localStorage.setItem('fe-version', fe_version)
//         window.location.reload() // For new version, simply reload on any get
//       }
//       return Promise.resolve(resp)
//     },
//   )

Vue.use(VueHtmlToPaper);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
