const state = {
  Bookies: [],
  BookiesDetail: [],
  HpNumbers: [],
  HpBookies: [],
  HpList: [], //เก็บเลขอั้นตามเจ้า
};

const getters = {
  Bookies: (state) => state.Bookies,
  BookiesDetail: (state) => state.BookiesDetail,
  HpNumbers: (state) => state.HpNumbers,
  HpBookies: (state) => state.HpBookies,
  getHpBtTypeById: (state) => (id) => {
    return state.HpBookies.find((HpBookies) => HpBookies.ReBtHostID === id)
      .BtType;
  },
  HpList: (state) => state.HpList,
};

const mutations = {
  set_list: (state, Bookies) => {
    state.Bookies = JSON.parse(JSON.stringify(Bookies));
  },
  del_bookie: (state, BookieID) => {
    var index = state.Bookies.findIndex((Bookies) => Bookies.id == BookieID);
    state.Bookies.splice(index, 1);
  },
  set_bookie_detail: (state, BookiesDetail) => {
    state.BookiesDetail = JSON.parse(JSON.stringify(BookiesDetail));
  },
  set_bookie_list: (state, Bookies) => {
    state.Bookies = JSON.parse(JSON.stringify(Bookies));
  },
  set_hp_bookie_list: (state, HpBookies) => {
    state.HpBookies = JSON.parse(JSON.stringify(HpBookies));
  },
  set_hp_list: (state, HpList) => {
    state.HpList = JSON.parse(JSON.stringify(HpList));
    // console.log(state.HpBookies);
    // console.log(state.HpList);
  },
};

const actions = {
  SendBookieData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              switch (credentials.Method) {
                case "GETLIST":
                  //LIST TYPE
                  switch (credentials.ListType) {
                    case "GENARAL_LIST":
                      context.commit("set_list", data.response.list);
                      break;
                  } //END LIST TYPE
                  break;
                case "GET":
                  context.commit(
                    "set_bookie_detail",
                    data.response.bookie_detail
                  );
                  resolve(data.response.bookie_detail);
                  break;
                case "POST": //INSERT
                //   context.commit("set_list", data.response.list);
                  break;
                case "PUT":
                  break;
                case "DEL":
                  if (parseInt(data.response.reload)) {
                    // console.log("reload");
                    context.commit("set_list", data.response.list);
                  } else {
                    // console.log("notreload");
                    context.commit("del_bookie", data.response.BookieID);
                  }
                  break;
                case "PUT_PRIZE_PERCENT":
                  context.commit("set_list", data.response.list);
                  break;
                default:
                  break;
              }

              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  get_detail(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/get_detail.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              // console.log("data");
              console.log(data);
              context.commit("set_bookie_detail", data.response.bookie_detail);
              resolve(data.response.bookie_detail);

              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_hp_bynumber(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              context.commit("set_bookie_list", data.response.bookie_list);
              context.commit("set_hp_list", data.response.hps);

              //context.commit('set_genaral_list',data.response.genaral_list);
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_hp_bookie(context, credentials) {
    // console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          //console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit(
                "set_hp_bookie_list",
                data.response.hp_bookie_list
              );
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  hp_add(context, credentials) {
    //console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/hp_add.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              context.commit(
                "set_hp_bookie_list",
                data.response.hp_bookie_list
              );
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  hp_del(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/hp_del.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit(
                "set_hp_bookie_list",
                data.response.hp_bookie_list
              );
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  set_selected(context, credentials) {
    //console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/set_selected.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          //console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              context.commit("set_list", data.response.genaral_list);
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_prize_percent(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/prize_percent_get.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              //context.commit('set_hp_bookie_list',data.response.hp_bookie_list);
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  edit_prize_percent(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("bookie/prize_percent_edit.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              //context.commit('set_hp_bookie_list',data.response.hp_bookie_list);
              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const bookie = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
