const state = {
  ExportDecides: [],
  ExportDecideSelect: [], //after select
  ExportDecideDetail: [],
};

const getters = {
  ExportDecides: (state) => state.ExportDecides,
  ExportDecideSelect: (state) => state.ExportDecideSelect,
  ExportDecideDetail: (state) => state.ExportDecideDetail,
};

const mutations = {
  set_export_decide_list: (state, ExportDecides) => {
    state.ExportDecides = JSON.parse(JSON.stringify(ExportDecides));
  },
  clear_export_decide_list: (state) => {
    state.ExportDecides = [];
  },
  set_export_decide_select: (state, ExportDecideSelect) => {
    state.ExportDecideSelect = JSON.parse(JSON.stringify(ExportDecideSelect));
  },
  set_export_decide_detail: (state, ExportDecideDetail) => {
    state.ExportDecideDetail = JSON.parse(JSON.stringify(ExportDecideDetail));
  },
  clear_export_decide_select: (state) => {
    state.ExportDecideDetail = [];
    state.ExportDecideSelect = [];
  },
};

const actions = {
  get_export_decide_list(context, credentials) {
    console.log(credentials);
    context.commit("clear_export_decide_list");
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_decide/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit(
                "set_export_decide_list",
                data.response.export_decide_list
              );
              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_export_decide_select(context, credentials) {
    console.log(credentials);
    context.commit("clear_export_decide_select");
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_decide/get_decide_select.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit(
                "set_export_decide_select",
                data.response.export_decide_select
              );
              context.commit(
                "set_export_decide_detail",
                data.response.export_decide_detail
              );
              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },  
  get_export_process(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_decide/export_process.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
            //   console.log(data);

              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },  
  
};

export const export_decide = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
