const state = {
    Profits: [],
    // ID
    // Name
    // Tel
    // Prize
    // Percent
    // Credit
    // Not_Send
    // BetSum
    // BetSumLoy
    // Status
};

const getters = {
    Profits:state=>state.Profits
};

const mutations = {
    set_profit_list: (state,Profits) => {
        state.Profits = JSON.parse(JSON.stringify(Profits));
    }
};

const actions = {
    get_profit_list(context, credentials){
        console.log(credentials);
        return new Promise((resolve, reject) => {
            this._vm.$http
            .post("export_process/get_list.php",credentials,this._vm.$headers)
            .then(({data,status}) =>{
                console.log(data);
                if(status===200){
                    if(data.message=="Success"){
                        console.log(data);
                        context.commit('set_profit_list',data.response.profit_loss_list);
                        resolve(data.response);

       //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
                    }else{
                        //console.log(data);

                        reject(data.message);
                    }
                }
            })
            .catch(error =>{
               console.log(error);
               reject(error);
            });
       });


    },

};

export const export_process = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters  
};