import Vue from "vue";
import VueRouter from "vue-router";
import SplashScreen from "../views/MainPages/SplashScreen.vue";

Vue.use(VueRouter);

const routes = [
    // main
  { path: "/", name: "SplashScreen", component: SplashScreen },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/MainPages/Login.vue"),
  },
  {
    path: "/CreatePincode",
    name: "CreatePincode",
    component: () => import("../views/MainPages/CreatePincode.vue"),
  },  
  {
    path: "/PinCode",
    name: "PinCode",
    component: () => import("../views/MainPages/PinCode.vue"),
  },
  {
    path: "/menu_data",
    name: "Menu_Data",
    component: () => import("../views/Menu/Menu_Data.vue"),
  },
  {
    path: "/menu_cus",
    name: "Menu_Cus",
    component: () => import("../views/Menu/Menu_Cus.vue"),
  },
  {
    path: "/menu_export",
    name: "Menu_Export",
    component: () => import("../views/Menu/Menu_Export.vue"),
  },
  {
    path: "/menu_staff",
    name: "Menu_Staff",
    component: () => import("../views/Menu/Menu_Staff.vue"),
  },
  {
    path: "/menu_manage",
    name: "Menu_Manage",
    component: () => import("../views/Menu/Menu_Manage.vue"),
  },
//   Data
{
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Data/Dashboard/Main.vue"),
  },
  {
    path: "/hp_number",
    name: "Hp_Number",
    component: () => import("../views/Data/Hp_Number.vue"),
  },
  {
    path: "/limit_number",
    name: "Limit_Number",
    component: () => import("../views/Data/Limit_Number.vue"),
  },
  {
    path: "/bookie",
    name: "Bookie",
    component: () => import("../views/Data/Export/Bookie.vue"),
  },
  {
    path: "/export_begin",
    name: "Export_Begin",
    component: () => import("../views/Data/Export/Export_Begin.vue"),
  },
  {
    path: "/export_process",
    name: "Export_Process",
    component: () => import("../views/Data/Export/Export_Process.vue"),
  },
  {
    path: "/export_page",
    name: "Export_Page",
    component: () => import("../views/Data/Export/Export_Pages.vue"),
  },
  {
    path: "/export_decide",
    name: "Export_Decide",
    component: () => import("../views/Data/Export/Export_Decide.vue"),
  },
  {
    path: "/export_final",
    name: "Export_Final",
    component: () => import("../views/Data/Export/Export_Final.vue"),
  },
  {
    path: "/export_page_list",
    name: "Export_Page_List",
    component: () => import("../views/Data/Export/Export_Page_List.vue"),
  },
  {
    path: "/export_page_edit",
    name: "Export_Page_edit",
    component: () => import("../views/Data/Export/Export_Page_Edit.vue"),
  },
  {
    path: "/export_page_print",
    name: "Export_Page_Print",
    component: () => import("../views/Data/Export/Export_Page_Print.vue"),
  },
  {
    path: "/export_page_image",
    name: "Export_Page_Image",
    component: () => import("../views/Data/Export/Export_Page_Image.vue"),
  },
  {
    path: "/period_setting",
    name: "Period_Setting",
    component: () => import("../views/Data/Period_Setting/Period_Setting.vue"),
  },
  {
    path: "/find_bet",
    name: "Find_Bet",
    component: () => import("../views/Data/Find_Bet/Find_Bet.vue"),
  },  
//   Cus
  {
    path: "/line_data",
    name: "Line_Data",
    component: () => import("../views/Cus/Line_Data.vue"),
  },
  {
    path: "/agent_data",
    name: "Agent_Data",
    component: () => import("../views/Cus/Agent_Data.vue"),
  },
  {
    path: "/pages_list",
    name: "Pages",
    component: () => import("../views/Cus/Pages.vue"),
  },
  {
    path: "/page",
    name: "Page",
    component: () => import("../views/Cus/Page.vue"),
  },
  {
    path: "/key",
    name: "Key",
    component: () => import("../views/Cus/Key.vue"),
  },
  //Manage
  {
    path: "/new_period",
    name: "New_Period",
    component: () => import("../views/Manage/New_Period/New_Period.vue"),
  },    
  {
    path: "/new_period_success",
    name: "New_Period_Success",
    component: () => import("../views/Manage/New_Period/New_Period_Success.vue"),
  },  
];

// router.onError(error =>{    

//     if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
//         window.location.reload()
//     }

// }); // onError

const router = new VueRouter({
  routes,
});

export default router;
