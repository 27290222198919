const state = {
  Bookies: [],
  ExportNumber: [],
};

const getters = {
  Bookies: (state) => state.Bookies,
  ExportNumber: (state) => state.ExportNumber,
};

const mutations = {
  set_bookie_list: (state, Bookies) => {
    state.Bookies = JSON.parse(JSON.stringify(Bookies));
  },
  set_export_number_list: (state, ExportNumber) => {
    state.ExportNumber = JSON.parse(JSON.stringify(ExportNumber));
  },
  //   set_export_decide_list: (state, ExportDecides) => {
  //     state.ExportDecides = JSON.parse(JSON.stringify(ExportDecides));
  //   },
  //   clear_export_decide_list: (state) => {
  //     state.ExportDecides = [];
  //   },
};

const actions = {
  get_list(context, credentials) {
    console.log(credentials);
    // context.commit("clear_export_decide_list");
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_final/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit(
                "set_bookie_list",
                data.response.bookie_list
              );
              context.commit(
                "set_export_number_list",
                data.response.bynumber
              );
              
              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  confirm(context, credentials) {
    console.log(credentials);
    // context.commit("clear_export_decide_list");
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_final/confirm.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              
              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const export_final = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
