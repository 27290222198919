<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    FUNHOST VERION 1.0 ...
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
  props: ["isLoading"],
  mounted() {
    setTimeout(() => {
      this.onload();
      //   if (localStorage.getItem("blog_token") != null) {

      //     this.$router.push("/Pincode");
      //   } else {
      //     this.$router.push("/Login");
      //   }
    }, 2000);
  },
  methods: {
    onload() {
      console.log("checking token");
      this.doChecktoken();
    },
    async doChecktoken() {
      await this.$store.dispatch("auth/ck_token").then(
        (response) => {
          //this.$router.push("/Dashboard");
          this.$router.push({
            name: "PinCode",
            params: { nickname: response },
          });
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "USER_NOTFOUND":
              localStorage.removeItem("blog_token");
              this.$router.push("/Login");
              break;
            case "PINCODE_NOTFOUND":
              this.$router.push("/CreatePincode");
              break;
          }
        }
      );
    },
  },
};
</script>

<style>
.loader {
  background-color: #63ab97;
  bottom: 0;
  color: white;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 3s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
