const state = {
  HPList: [],
  GroupList: [],
  // BetTypeDetilID
  // BetNumber
  // TimeBegin
  // Percent
  // SetGroup
  // TimeEnd
  // SetBy
  // IsAtive
};

const getters = {
  HPList: (state) => state.HPList,
  GroupList: (state) => state.GroupList,
  //   getGroup: (state) => {

  //     // console.log(state.pages);
  //     // console.log("12");
  //     // console.log(state.pages.find((t) => t.id === 1));
  //     //console.log("34");
  //     //console.log(state.HPList.find((HP) => HP.BetTypeDetilID === "1"));
  //     // console.log(state.pages);
  //     // console.log(state.HPList);
  //     // console.log(state.HPList.find((HP) => HP.BetTypeDetilID === "1"));
  //     // console.log("BetTypeDetilID");
  //     // console.log(state.pages.find((HP) => HP.content === "Page 2"));
  //     return state.HPList.find((HP) => HP.BetTypeDetilID === "1");
  //   },
};

const mutations = {
  setHPList: (state, HPList) => {
    state.HPList = HPList;
  },
  setGroupList: (state, GroupList) => {
    //round
    state.GroupList = GroupList;
    
    //type
    for (let g_key in state.GroupList) {
      console.log("round : " + state.GroupList[g_key]["SetGroup"]);

      //bt prize
      let hp3digit = [];
      let hp2digit = [];
      for (let h_key in state.HPList) {
        if (state.HPList[h_key]["SetGroup"] == state.GroupList[g_key]["SetGroup"]) {
          if (state.HPList[h_key]["BetTypeDetailID"] == "1") {
            let Percent=[];
            if(parseInt(state.HPList[h_key]["Percent"])>0){
                if(parseInt(state.HPList[h_key]["Percent"])==50){
                    Percent["prize"]="จ่ายครึ่ง";
                }else{
                    Percent["prize"]="จ่าย " + state.HPList[h_key]["Percent"] + "%";
                }               
            }else{
                Percent["prize"]="3 ตัวตรงจ่ายบาทละ " + Math.abs(state.HPList[h_key]["Percent"]);//state.HPList[h_key]["Percent"] + "," + state.HPList[h_key]["Pay"];
                if(state.HPList[h_key]["Pay"]>0){
                    Percent["prize"] += " (จ่ายคนแทง " + state.HPList[h_key]["Pay"] + ")";
                }
                for (let h_key2 in state.HPList) {
                    if (state.HPList[h_key2]["BetTypeDetailID"] == "2") {
                        if(state.HPList[h_key].BetNumber==state.HPList[h_key2].BetNumber){//อาจจะเพิ่มสลับตำแหน่ง
                            Percent["prize"] += " โต็ด " + Math.abs(state.HPList[h_key2]["Percent"]);
                        }
                    }
                }
            }
            //%
            let dupplicate = false;
            for (let i = 0; i < hp3digit.length; i++) {
              if (hp3digit[i]["prize"] == Percent["prize"]) {
                dupplicate = true;
                hp3digit[i]["Number"].push(state.HPList[h_key]);
              }
            }
            if (dupplicate == false) {
                Percent["Number"]=[];
                Percent["Number"].push(state.HPList[h_key]);
                hp3digit.push(Percent);    
            }
          }

          if (state.HPList[h_key]["BetTypeDetailID"] == "4") {
            //%
            let Percent=[];
            Percent["prize"]=state.HPList[h_key]["Percent"] + "," + state.HPList[h_key]["Pay"];

            if(parseInt(state.HPList[h_key]["Percent"])>0){
                if(parseInt(state.HPList[h_key]["Percent"])==50){
                    Percent["prize"]="จ่ายครึ่ง";
                }else{
                    Percent["prize"]="จ่าย " + state.HPList[h_key]["Percent"] + "%";
                }               
            }else{
                Percent["prize"]="3 ตัวตรงจ่ายบาทละ " + Math.abs(state.HPList[h_key]["Percent"]);//state.HPList[h_key]["Percent"] + "," + state.HPList[h_key]["Pay"];
                if(state.HPList[h_key]["Pay"]>0){
                    Percent["prize"] += " (จ่ายคนแทง " + state.HPList[h_key]["Pay"] + ")";
                }
            }


            let dupplicate = false;
            for (let i = 0; i < hp2digit.length; i++) {
              if (hp2digit[i]["prize"]== Percent["prize"]) {
                dupplicate = true;
                //hp2digit[i]["Number"].push(state.HPList[h_key]["BetNumber"]);
                hp2digit[i]["Number"].push(state.HPList[h_key]);
              }
            }
            //Percent["Number"].push(state.HPList[h_key]["BetNumber"]);
            if (dupplicate == false) {
                Percent["Number"]=[];
                //Percent["Number"].push(state.HPList[h_key]["BetNumber"]);
                Percent["Number"].push(state.HPList[h_key]);
                hp2digit.push(Percent); 
            }
          }
        }
      }

      state.GroupList[g_key]["hp3digit"]=hp3digit;
      state.GroupList[g_key]["hp2digit"]=hp2digit;

      //add type
      //bon lang


      //insert in group
      //state.GroupList[g_key]["SetGroup"];
      //console.log(state.GroupList[g_key]);
    }
    console.log("GroupList");
    console.log(state.GroupList);
  },
};

const actions = {
  getHPs(context, parameter) {
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("data/hp/get_list.php", parameter, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //   console.log(data.response);
              //   console.log(data.response.HPList);
              //let message = JSON.parse(JSON.stringify(data.response));
              //   console.log(message.HPList);
              context.commit("setHPList", data.response.HPList);
              context.commit("setGroupList", data.response.GroupList);

              //context.commit("setHPList", data.response.HPList);

              //  console.log("--> " + context.state.HPs[0]);
              //                 context.commit('loginSuccess',data.response.user);
              resolve(data.response);

              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  getHPShow(context, parameter) {
    // console.log(parameter);
    // console.log(this._vm.$headers);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("data/hp/get_list.php", parameter, this._vm.$headers)
        .then(({ data, status }) => {
        //    console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
            //   console.log(data.response);
              context.commit("setHPList", data.response.HPList);
              resolve(data.response);
            } else {
              console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const hp = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
