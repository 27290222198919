const state = {
  Lines: [],
  Page: [],
};

const getters = {
  Lines: (state) => state.Lines,
  Page: (state) => state.Page,
};

const mutations = {
  set_line_list: (state, Lines) => {
    state.Lines = JSON.parse(JSON.stringify(Lines));
    //loop
    //type
    let PriceSumLine = 0;
    let ShowLine = 1;
    for (let g_key in state.Lines) {
      PriceSumLine +=
        parseInt(state.Lines[g_key]["PriceSum"]) +
        parseInt(state.Lines[g_key]["PriceLoy"]);
      state.Lines[g_key]["PriceSumLine"] = PriceSumLine;
      state.Lines[g_key]["ShowLine"] = ShowLine;
      ShowLine++;
    }
  },
  add_line_list: (state, Line) => {
    Line = JSON.parse(JSON.stringify(Line));

    Line["PriceSumLine"] = 0;
    if (state.Lines.length > 0) {
      Line["PriceSumLine"] =
        parseInt(state.Lines[state.Lines.length - 1]["PriceSumLine"]) +
        Line["PriceSum"] +
        Line["PriceLoy"];
    } else {
      Line["PriceSumLine"] = Line["PriceSum"] + Line["PriceLoy"];
    }

    Line["ShowLine"] = state.Lines.length+1;
    console.log(Line["ShowLine"]);
    state.Lines.push(Line);

    state.Page["PriceSum"] =
      parseInt(state.Page["PriceSum"]) + parseInt(Line["PriceSum"]);
    state.Page["PriceSumLoy"] =
      parseInt(state.Page["PriceSumLoy"]) + parseInt(Line["PriceLoy"]);
  },
  set_page_data: (state, Page) => {
    state.Page = JSON.parse(JSON.stringify(Page));
  },
  update_page_data: (state, Page) => {
    Page = JSON.parse(JSON.stringify(Page));
    state.Page["UpdateDT"] = Page["LastUpdate"];
    state.Page["Status"] = Page["Status"];
    //state.Page = JSON.parse(JSON.stringify(Page));
  },
};

const actions = {
  get_list(context, credentials) {
    // console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("page/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          // console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data.response);
              context.commit("set_line_list", data.response.line_list);
              context.commit("set_page_data", data.response.page_data);

              resolve(data.response);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  line_add(context, credentials) {
    //console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("page/line_add.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
           console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
            //   console.log(data);

              context.commit("add_line_list", data.response.RecentBetLine);
              context.commit("update_page_data", data.response.PageData);

              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  line_del(context, credentials) {
    //console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("page/line_del.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);

            //   context.commit("add_line_list", data.response.RecentBetLine);
            //   context.commit("update_page_data", data.response.PageData);

              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  line_sendback(context, credentials) {
    //console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("page/line_sendback.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const page = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
