const state = {
  ReBtHosts: [],
  Pages: [],
  PagesDetail: [{ PriceSum: 0, PriceSumLoy: 0, WinSum: 0, WinSumLoy: 0 }], //PriceSum,PriceSumLoy,WinSum,
  PageData: [],
};

const getters = {
  ReBtHosts: (state) => state.ReBtHosts,
  Pages: (state) => state.Pages,
  PagesDetail: (state) => state.PagesDetail,
  PageData: (state) => state.PageData,
};

const mutations = {
  set_genaral_list: (state, ReBtHosts) => {
    state.ReBtHosts = JSON.parse(JSON.stringify(ReBtHosts));
  },
  set_list: (state, Pages) => {
    state.Pages = JSON.parse(JSON.stringify(Pages));
    state.PagesDetail["PriceSum"] = 0;
    state.PagesDetail["PriceSumLoy"] = 0;
    state.PagesDetail["WinSum"] = 0;
    state.PagesDetail["WinSumLoy"] = 0;
    state.PagesDetail["WaitingSend"] = 0;
    for (let Key in Pages) {
      state.PagesDetail["checked"] = false;
      if (
        Pages[Key].Status == 1 ||
        Pages[Key].Status == 3 ||
        Pages[Key].Status == 4
      ) {
        state.PagesDetail["PriceSum"] += parseInt(Pages[Key].PriceSum);
        state.PagesDetail["PriceSumLoy"] += parseInt(Pages[Key].PriceSumLoy);
        state.PagesDetail["WinSum"] += parseInt(Pages[Key].WinSum);
        state.PagesDetail["WinSumLoy"] += parseInt(Pages[Key].WinSumLoy);
      }

      if (
        parseInt(Pages[Key].PriceSum) + parseInt(Pages[Key].PriceSumLoy) > 0 &&
        Pages[Key].Status == 0
      )
        state.PagesDetail["WaitingSend"] += 1;
    }
  },
  set_page_data: (state, PageData) => {
    state.PageData = JSON.parse(JSON.stringify(PageData));
  },
  //   clear_export_decide_list: (state) => {
  //     state.ExportDecides = [];
  //   },
};

const actions = {
  get_list(context, credentials) {
    // console.log(credentials);
    // context.commit("clear_export_decide_list");
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/get_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_genaral_list", data.response.genaral_list);
              resolve(data.response);

              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);

              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_page_list(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/get_page_list.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          // console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              context.commit("set_list", data.response.pagelist);
              resolve(data.response);
              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  merge_page(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/merge_page.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              context.commit("set_list", data.response.pagelist);
              resolve(data.response);
              //               context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_page_data(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/get_page_data.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_page_data", data.response.page_data);
              resolve(data.response);
              //               context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  get_page_data_edit(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/get_page_data_edit.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_page_data", data.response.page_data);
              resolve(data.response);
              //               context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  new_page(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/add_page.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          // console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              //console.log(data);
              //context.commit('set_list',data.response.pagelist);
              resolve(data.response);
              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  del_page(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/del_page.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_list", data.response.pagelist);
              resolve(data.response);
              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  add_line(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/add_line.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              context.commit("set_page_data", data.response.page_data);
              resolve(data.response);
              //                 context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  print_page(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("export_page/print_page.php", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              console.log(data);
              //context.commit('lotto/set_draw_date',data.response.period, { root: true });
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export const export_page = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
