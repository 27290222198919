
const state = {
};

const getters = {
};

const mutations = {
};

const actions = {

};

export const lotto = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
